import { renderHTMLParser } from '~/utils/content/default/content-parser';
import { renderGutenbergParser } from '~/utils/content/gutenberg/content-parser';
import { useAmpCustomElement } from '@devhacker/shared/composables/useAmpCustomElement';

export default defineNuxtComponent({
  name: 'TheContent',

  props: {
    elem: {
      type: Object as PropType<TContent>,
      required: true,
    },
    lazyLoadingEnabled: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    if (!props.elem) {
      console.trace(`TheContent: elem is not defined`, props);
      return () => null;
    }

    // Не имеет отдельного компонента, рендерится через default
    // Потому подключаем элемент в the content
    if (props.elem.type === 'amp-viqeo-player') {
      useAmpCustomElement('amp-viqeo-player');
    }

    const nuxtApp = useNuxtApp();
    const renderByType = {
      gutenberg_parser: renderGutenbergParser,
      html_parser: renderHTMLParser,
    };

    const currentRender = renderByType[props.elem.parser_type ?? 'html_parser'];
    const context = {
      lazyLoadingEnabled: props.lazyLoadingEnabled,
      location: props.location,
      isAMP: nuxtApp.$isAMP,
    };

    if (currentRender) {
      return () => currentRender(props.elem, h, context);
    } else {
      console.error(
        `Not found parser for component: ${props.elem.type} with parser type: ${props.elem.parser_type}`,
      );
      return () => null;
    }
  },
});
