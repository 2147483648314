import type { CreateElement, VNode, RenderContext } from 'vue';
import { createTextVNode } from 'vue';
import TheContent from '~/components/content/TheContent';

import { isArray, IN_BROWSER, isAnotherOrigin } from '~/utils';

// Все компоненты обязательно должны импортироваться асинхронно!
// https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
const LhCarousel = defineAsyncComponent(() => import('~/components/slider/LhCarousel'));
const LhTopicsList = defineAsyncComponent(() => import('~/components/LhTopicsList'));
const NewsletterSubscriptionFormContainer = defineAsyncComponent(
  () =>
    import(
      '~/containers/NewsletterSubscriptionFormContainer/NewsletterSubscriptionFormContainer.vue'
    ),
);
const TheTable = defineAsyncComponent(() => import('~/components/content/components/TheTable.vue'));

// AMP
const AmpImageWrapper = defineAsyncComponent(
  () => import('~/components/AmpImageWrapper.amp/AmpImageWrapper.amp.vue'),
);
const NewsletterSubscriptionFormContainerAmp = defineAsyncComponent(
  () =>
    import(
      '~/containers/NewsletterSubscriptionFormContainer/NewsletterSubscriptionFormContainer.amp.vue'
    ),
);
const AmpIframeWrapper = defineAsyncComponent(
  () => import('~/components/AmpIframeWrapper.amp/AmpIframeWrapper.amp.vue'),
);

const components: Record<string, any> = {
  LhCarousel,
  LhTopicsList,
  'amp-image-wrapper': AmpImageWrapper,
  'amp-iframe-wrapper': AmpIframeWrapper,
  'lh__email-block': NewsletterSubscriptionFormContainer,
  'lh-email-block': NewsletterSubscriptionFormContainer,
  table: TheTable,

  // AMP
  'lh__email-block-amp': NewsletterSubscriptionFormContainerAmp,
};

export const renderHTMLParser = (elem: TContent, h: CreateElement, context: any): VNode => {
  const { type, text, attributes, children } = elem;
  const node = components[type] || type;
  const { lazyLoadingEnabled = false, location = null, isAMP = false } = context;

  if (type === 'text' || type === '#text' || type === '#cdata-section') {
    return createTextVNode(text);
  }

  if (type === 'img' && lazyLoadingEnabled) {
    return h(node, {
      ...attributes,
      loading: 'lazy',
      style: attributes?.style ?? null,
    });
  }

  let childNodes: VNode[] = [];
  if (isArray(children)) {
    childNodes = children.map((item: TContent) => {
      return h(TheContent, { elem: item, lazyLoadingEnabled, location });
    });
  }

  if (type === 'amp-youtube' || type === 'amp-viqeo-player') {
    delete attributes.src;
  }

  if (isAMP && attributes.href && isAnotherOrigin(attributes.href)) {
    attributes['data-vars-link-href'] = attributes.href;
  }

  return h(
    node,
    {
      ...attributes,
      location,
      style: attributes?.style ?? null,
    },
    childNodes,
  );
};
