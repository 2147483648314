import type { h as hFunction, VNode } from 'vue';
import TheContent from '~/components/content/TheContent';
import { isArray, isAnotherOrigin } from '~/utils/index';
import { createTextVNode } from 'vue';

const ReadAlso = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/ReadAlso.vue'),
);
const PromoBlock = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/PromoBlock.vue'),
);
const ArticleCarousel = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/ArticleCarousel.vue'),
);
const CoreButtons = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/CoreButtons.vue'),
);
const Box = defineAsyncComponent(() => import('~/components/content/components/gutenberg/Box.vue'));
const Spoiler = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Spoiler.vue'),
);
const CharactersList = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/CharactersList.vue'),
);
const Ref = defineAsyncComponent(() => import('~/components/content/components/gutenberg/Ref.vue'));
const PullQuote = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/PullQuote.vue'),
);
const BQuote = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/BQuote.vue'),
);
const AskQuestion = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/AskQuestion.vue'),
);
const AppBox = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/AppBox.vue'),
);
const Embed = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Embed.vue'),
);
const CoreColumns = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/CoreColumns.vue'),
);
const CoreColumn = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/CoreColumn.vue'),
);
const TopicsList = defineAsyncComponent(() => import('~/components/LhTopicsList/LhTopicsList.vue'));
const CoreGroup = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/CoreGroup.vue'),
);
const GoodsGallery = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/GoodsGallery.vue'),
);
const Goods = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Goods.vue'),
);
const Gallery = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Gallery.vue'),
);
const TableOfContents = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/TableOfContents.vue'),
);
const CoreHtml = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/CoreHtml'),
);
const VatCalculator = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/VatCalculator.vue'),
);
const BmiCalculator = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/BmiCalculator.vue'),
);
const AgeCalculator = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/AgeCalculator.vue'),
);
const OvulationCalculator = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/OvulationCalculator.vue'),
);

// ADS
const AdsContactForm = defineAsyncComponent(
  () => import('~/components/content/components/AdsContactForm.vue'),
);
const AdsContactsList = defineAsyncComponent(
  () => import('~/components/content/components/AdsContactsList.vue'),
);
const AdsUsefulInfo = defineAsyncComponent(
  () => import('~/components/content/components/AdsUsefulInfo/AdsUsefulInfo.vue'),
);

// AMP
const ReadAlsoAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/ReadAlso.amp.vue'),
);
const PromoBlockAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/PromoBlock.amp.vue'),
);
const ArticleCarouselAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/ArticleCarousel.amp.vue'),
);
const CoreButtonsAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/CoreButtons.amp.vue'),
);
const BoxAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/Box.amp.vue'),
);
const SpoilerAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/Spoiler.amp.vue'),
);
const CharactersListAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/CharactersList.amp.vue'),
);
const RefAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/Ref.amp.vue'),
);
const AskQuestionAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/AskQuestion.amp.vue'),
);
const AppBoxAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/AppBox.amp.vue'),
);
const EmbedAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/Embed.amp.vue'),
);
const QuizOrPoll = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/QuizOrPoll.vue'),
);
const GoodsGalleryAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/GoodsGallery.amp.vue'),
);
const GoodsAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/Goods.amp.vue'),
);
const TableOfContentsAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/TableOfContents.amp.vue'),
);
const HtmlFallback = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/HtmlFallback.amp.vue'),
);
const GalleryAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/Gallery.amp.vue'),
);
const VatCalculatorAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/VatCalculator.amp.vue'),
);
const AgeCalculatorAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/AgeCalculatorAmp.amp.vue'),
);
const AdsContactsListAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/AdsContactsList.amp.vue'),
);
const AdsUsefulInfoAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/AdsUsefulInfo.amp.vue'),
);
const AdsContactFormAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/AdsContactForm.amp.vue'),
);
const BmiCalculatorAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/BmiCalculator.amp.vue'),
);
const OvulationCalculatorAmp = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Amp/OvulationCalculator.amp.vue'),
);
// Health
const HealthTv = defineAsyncComponent(
  () => import('~/containers/HealthTvContainer/HealthTvContainer.vue'),
);
const HealthLargeCard = defineAsyncComponent(
  () => import('~/containers/HealthLargeCardContainer/HealthLargeCardContainer.vue'),
);
const HealthButtonCollection = defineAsyncComponent(
  () => import('~/containers/HealthButtonCollection/HealthButtonCollectionBlock.vue'),
);
const HealthPostsCollection = defineAsyncComponent(
  () => import('~/containers/HealthPostsCollection/HealthPostsCollection.vue'),
);
const Archive = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Archive.vue'),
);
const Tags = defineAsyncComponent(
  () => import('~/components/content/components/gutenberg/Tags/TagsBlock.vue'),
);
const HealthExperts = defineAsyncComponent(() => import('~/components/HealthExperts.vue'));

const components: Record<string, any> = {
  // Read also - читайте также
  'lh__read-also': ReadAlso,
  'lh__read-also-amp': ReadAlsoAmp,

  // Promo block - промоблок
  'lh__promo-block': PromoBlock,
  'lh__promo-block-amp': PromoBlockAmp,

  // Article carousel - карусель
  lh__carousel: ArticleCarousel,
  'lh__carousel-amp': ArticleCarouselAmp,

  // Buttons - кнопки
  core__buttons: CoreButtons,
  'core__buttons-amp': CoreButtonsAmp,

  // Spoiler - спойлер
  lh__spoiler: Spoiler,
  'lh__spoiler-amp': SpoilerAmp,

  // Appbox - аппбокс
  lh__appbox: AppBox,
  'lh__appbox-amp': AppBoxAmp,

  // Boxes - белый бокс, серый бокс
  lh__greybox: Box,
  'lh__greybox-amp': BoxAmp,

  lh__whitebox: Box,
  'lh__whitebox-amp': BoxAmp,

  // Калькулятор НДС - nds-calculator
  'lh__nds-calculator': VatCalculator,
  'lh__nds-calculator-amp': VatCalculatorAmp,

  // Калькулятор ИМТ - bmi-calculator
  'lh__bmi-calculator': BmiCalculator,
  'lh__bmi-calculator-amp': BmiCalculatorAmp,

  // Калькулятор возраста
  'lh__age-calculator': AgeCalculator,
  'lh__age-calculator-amp': AgeCalculatorAmp,

  // Калькулятор овуляции - bmi-calculator
  'lh__ovulation-calculator': OvulationCalculator,
  'lh__ovulation-calculator-amp': OvulationCalculatorAmp,

  // Quotes - цитата с фото, об авторе, простая цитата, авторская цитата, врезка
  // Прямая речь - возможно устарела
  'lh__speech-bquote': CharactersList,
  'lh__speech-bquote-amp': CharactersListAmp,

  // Об авторе
  'lh__author-bquote': CharactersList,
  'lh__author-bquote-amp': CharactersListAmp,

  // Цитата с фото
  'lh__characters-list': CharactersList,
  'lh__characters-list-amp': CharactersListAmp,

  // Авторская цитата
  'lh__review-bquote': CharactersList,
  'lh__review-bquote-amp': CharactersListAmp,

  // Врезка
  'lh__cut-bquote': PullQuote,
  'lh__cut-bquote-amp': PullQuote,

  // Простая цитата
  'lh__simply-bquote': BQuote,
  'lh__simply-bquote-amp': BQuote,

  // Refs - сноска
  'lh-reference': Ref,
  'lh-reference-amp': RefAmp,

  // Ask question - задать вопрос
  'lh__qna-block': AskQuestion,
  'lh__qna-block-amp': AskQuestionAmp,

  // Generic embed - вставки tiktok, pinterest, youtube и т.д.
  core__embed: Embed,
  'core__embed-amp': EmbedAmp,
  'html-fallback-amp': HtmlFallback,

  // Generic html - кастомный html
  core__html: CoreHtml,

  // Gallery - галерея
  core__gallery: Gallery,
  'core__gallery-amp': GalleryAmp,

  // Goods gallery - галерея товаров
  'lh__goods-gallery': GoodsGallery,
  'lh__goods-gallery-amp': GoodsGalleryAmp,

  // Goods - товар
  lh__goods: Goods,
  'lh__goods-amp': GoodsAmp,

  // Quizzes - квиз, тест
  lh__poll: QuizOrPoll,
  lh__quiz: QuizOrPoll,

  // Table of contents template specific - формируется для шаблона с оглавлением, в админке не добавляется
  'core__table-of-contents': TableOfContents,
  'core__table-of-contents-amp': TableOfContentsAmp,

  // Element grouping same for amp and non-amp - группа, колонки
  core__group: CoreGroup,
  core__column: CoreColumn,
  core__columns: CoreColumns,

  // Topics page specific - рубрики
  lh__topics: TopicsList,

  // ADS page specific - Контакты, напишите нам, полезная информация
  'lh__ads-page-contact-us-form': AdsContactForm,
  'lh__ads-page-contact-us-form-amp': AdsContactFormAmp,
  'lh__ads-page-contacts-list': AdsContactsList,
  'lh__ads-page-contacts-list-amp': AdsContactsListAmp,
  'lh__ads-page-useful-info': AdsUsefulInfo,
  'lh__ads-page-useful-info-amp': AdsUsefulInfoAmp,

  // Health
  'lh__health-subcats': Tags,
  'lh__health-archive': Archive,
  'lh__health-tv': HealthTv,
  'lh__health-card': HealthLargeCard,
  'lh__health-buttoncollection': HealthButtonCollection,
  'lh__health-collection': HealthPostsCollection,
  'lh__health-card-editorial': HealthExperts,
  'lh__health-card-expert': HealthExperts,
};

export const renderGutenbergParser = (elem: TContent, h: typeof hFunction, context: any): VNode => {
  const { type, text, children, attributes } = elem;
  const textTypes = ['text', '#text', '#script-content'];
  const node = components[type] || type;

  if (textTypes.includes(type)) {
    return createTextVNode(text ?? '');
  }

  if (isArray(children)) {
    children.map((item) => {
      return h(TheContent, { elem: item });
    });
  }

  if (context.isAMP && attributes.href && isAnotherOrigin(attributes.href)) {
    attributes['data-vars-link-href'] = attributes.href;
  }

  return h(node, { elem });
};
